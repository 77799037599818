import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../components/Layout"
import whatsapp from "../images/whatsapp.svg"
import instagram from "../images/instagram.svg"
import youtube from "../images/youtube.svg"
import email from "../images/email.svg"
import location from "../images/location.svg"
import SubHeader from "../components/SubHeader"
import '../styles/global.css'
import ButtonTalk from "../components/ButtonTalk"

export default function Contact() {
  return (
    <Layout>
        <Helmet
            htmlAttributes={{ lang: "en" }}
            title="PlanetFx - Contact"
            meta={[{name:"viewport", content:"width=device-width, initial-scale=1.0"}, {name: "description", content: "Planet Fx adalah studio kreatif pembuatan Company Profile, Iklan, Explainer, ILM, Teaser, Bumper berupa Animasi Flat Motion Graphic dan juga pembuatan Media Interaktif dan Design"}]}>
        </Helmet>
        <SubHeader title="KONTAK"/>
        <main>
            <div className="layout">
                <section className="contact">
                    <article className="contact-content">
                        <h3>Informasi Kontak</h3>
                        <aside>
                            <figure>
                                <img src={whatsapp} alt="whatsapp"/>
                            </figure>
                            <a href="https://wa.me/6281770891682" target="_blank" rel="noreferrer">
                                <p>0817-7089-1682</p>
                            </a>
                        </aside>
                        <aside>
                            <figure>
                                <img src={instagram} alt="instagram"/>
                            </figure>
                            <a href="https://www.instagram.com/planetfx/" target="_blank" rel="noreferrer">
                                <p>planetfx</p>
                            </a>
                        </aside>
                        <aside>
                            <figure>
                                <img src={youtube} alt="youtube"/>
                            </figure>
                            <div className="inner-flex">
                                <a href="https://www.youtube.com/user/radius877/featured" target="_blank" rel="noreferrer">
                                    <p>PLANETFX</p>
                                </a>
                                <span>/</span>
                                <a href="https://www.youtube.com/channel/UC6nUpzPtb4DSH1vuZ9l_u0g" target="_blank" rel="noreferrer">
                                    <p>RemajaKampung.id</p>
                                </a>
                            </div>
                        </aside>
                        <aside>
                            <figure>
                                <img src={email} alt="email"/>
                            </figure>
                            <a href="mailto:radius.antero96@gmail.com"><p>radius.antero96@gmail.com</p></a>
                        </aside>
                        <aside>
                            <figure>
                                <img src={location} alt="location"/>
                            </figure>
                            <span>Jl. Abdul Rahman No. 44A, Dusun Alas Tipis Pabean, Kec. Sedati, Sidoarjo, Jawa Timur 61253.</span>
                        </aside>
                        <aside className="center-small">
                            <ButtonTalk className="btn-orange"/>
                        </aside>
                    </article>
                </section>
            </div>
        </main>
    </Layout>
  )
}